import "./App.css";
import Header from "./components/Header/Index";
import NavBarPS from "./components/NavBarPS/Index";
import manchaTop from "./assets/img/manchaTop.webp";
import { Box } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css"; // Importa los estilos CSS de AOS
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init(); // Inicializa AOS en el componente
  }, []);
  return (
    <>
      <Box
        sx={{
          background: `url(${manchaTop}) no-repeat left`,
          backgroundPosition: "top left",
          backgroundSize: {
            xs: "40%",
            md: "25%",
          },
        }}
      >
        <NavBarPS />
        <Header />
      </Box>
    </>
  );
}

export default App;
