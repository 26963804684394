import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LOGO from '../../assets/logo/logo.png';
import { Facebook, Instagram, ShareOutlined, YouTube } from '@mui/icons-material';
import Tiktok from '../../assets/icons/tiktok.png';
import manchaTop from '../../assets/img/manchaTop.webp';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';

const pages = [<a href='https://www.facebook.com/psrockolaofficial' target="_blank"  ><Facebook fontSize='large' /></a>, <a href='https://www.instagram.com/psrockola' target='_blank'><Instagram fontSize='large' /></a>, <a href='https://www.tiktok.com/@psrockola_official' target='_blank'> <img src={Tiktok} width={32} height={32} alt='' /></a>];



function NavBarPS() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [isScrolled, setIsScrolled] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };



  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar position={isMobile ? 'sticky' : 'relative'} sx={{
      boxShadow: 'none',
      background: `transparent url(${manchaTop}) no-repeat left`,
      backgroundPosition: "top right",
      backgroundPositionY: {
        xs: '-100px',
        md: '-150px'
      },
      backgroundPositionX: {
        xs: '118%',
        md: '110%'
      },
      backgroundSize: {
        xs: "150px",
        md: "250px",
        xl: "300px",
      },
      zIndex: 1000,
      padding: {
        xs: '15px 0',
        md: '0px 0',
      },
      backgroundColor: {
        xs: isScrolled ? 'rgba(255,255,255,1)' : 'transparent',
        md: 'transparent'
      }
    }}>
      <Container maxWidth="xl" >
        <Toolbar disableGutters sx={{ gap: 0, justifyContent: 'space-between' }}>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, }}>
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                fontWeight: 700,
                letterSpacing: '.3rem',
                fontSize: '1.5rem',
                textDecoration: 'none !important',
                alignItems: 'center',
                color: '#000 !important',
                display: 'flex'

              }}
            >
              <Avatar src={LOGO} sx={{ mr: 1 }} />
              PSROCKOLA
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#000"
            >
              <ShareOutlined />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={handleCloseNavMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
                </MenuItem>
              ))}

            </Menu>
          </Box>
          <Box sx={{ alignItems: 'center', justifyContent: 'center', }}>
            <Typography
              variant="h5"
              noWrap

              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontWeight: 700,
                fontSize: {
                  xs: '.9rem',
                  md: '1.5rem',
                },
                letterSpacing: '.3rem',
                textDecoration: 'none !important',
                alignItems: 'center',
                justifyContent: 'flex-start',

                color: '#000 !important',
              }}
            >

              <Avatar src={LOGO} sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
              PSROCKOLA
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', alignItems: 'center', justifyContent: 'flex-end', } }}>
            {pages.map((page, index) => (
              <Button
                key={index}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', minWidth: 'initial' }}
              >
                {page}
              </Button>
            ))}
          </Box>
        </Toolbar>

      </Container>
    </AppBar>
  );
}
export default NavBarPS;