import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import TELEFONO from '../../assets/img/telefono.png'
import FONDOTLF from '../../assets/img/fondoTlf.webp'
import './style.css'

export default function Header() {
    return (
        <div>
            <Container maxWidth="xl" >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={7} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                        <Typography variant="h4" component="h1" gutterBottom
                            data-aos="fade-down-right"
                            sx={{
                                fontSize: {
                                    md: '1.5rem',
                                    xs: '1rem'
                                },
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                
                                lineHeight: '1.9',
                                width: '100%',
                                textAlign: {
                                    md: 'center',
                                    xs: 'center'
                                },
                                padding: {
                                    xs: '2rem 0rem',
                                    md: '0'
                                }
                            }}>
                            Estamos trabajando arduamente para ofrecerte la mejor experiencia musical en línea. PSRockola es tu destino ideal para descubrir, compartir y disfrutar de la música que amas. Muy pronto podrás acceder a nuestras funciones innovadoras, listas de reproducción personalizadas y una comunidad vibrante de amantes de la música.

                            ¡Gracias por tu paciencia y por ser parte de nuestra comunidad! Mantente atento a nuestras actualizaciones y no dudes en seguirnos en nuestras redes sociales para recibir noticias emocionantes.

                            ¡La música está a solo un clic de distancia!
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            width: '100%',
                            padding: {
                                xs: '0px 0px 30px 0px',
                                md: '0px'
                            },
                        }}>
                          
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={4} lg={5} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: `url(${FONDOTLF})`,
                        backgroundSize: '90% 90%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <Box sx={{
                            display: { xs: 'flex', md: 'flex' }, width: { xs: '70%', md: 250, xl: 350 }, marginBottom: {
                                xs: '20px',
                                md: '0px'
                            },
                            position: 'relative',
                            flexDirection: 'column',
                        }}>
                            <img src={TELEFONO} alt="VideoRockola" width={'100%'} data-aos="fade-down-left" sstyle={{
                                transformOrigin: 'top',
                            }} />
                            
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
